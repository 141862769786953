import "./Welcome.css"
import Logo from "../../assets/images/pic.jpg"
import About from "./About"
import Footer from "../Footer/Footer"
import Contact from "../Contact/Contact"
import Carroussel from "../Carroussel/Carroussel"
import Cards from "../../assets/languagesCards.js"
import { Link } from 'react-scroll'
import SendEmail from "../../requests/SendEmail"
import strings from "../../assets/strings.js"

const Welcome = (language) => {
    return (
        <main>
            <section className="welcome" id="welcome">
                <div>
                    <img src={Logo} alt="logo" className="welcome--logo" />
                    <h2>{strings[language.language].home.title}</h2>
                    <p className="subtitle">{strings[language.language].home.welcome}</p>
                    <Link className="welcome__cta-primary" to="contact">{strings[language.language].home.contact}</Link>
                </div>
                <div className="rectangle rectangle1"></div>
                <div className="rectangle rectangle2"></div>
                <div className="rectangle rectangle3"></div>
                <div className="wave wave1"></div>
                <div className="wave wave2"></div>
                <div className="wave wave3"></div>
                <div className="wave wave4"></div>
            </section>
            <About />
            <Carroussel
                id='carroussel'
                cards={Cards}
                offset={3}
                showArrows={false}
            />
            {/* <div id="locate"><AddressMap /></div> */}
            <Contact id="contact" onAdd={SendEmail} language={language} />
            <Footer />
        </main>
    )
}

export default Welcome