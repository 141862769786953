import { GiHamburgerMenu } from 'react-icons/gi';

const HamburgerMenu = ({menu, onToggle}) => {

    
    return (
      <GiHamburgerMenu className={`navbar--hamburger`} onClick={() => onToggle(!menu)} />
    )
}

export default HamburgerMenu
