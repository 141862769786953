
import CardNews from "../components/Carroussel/CardNews"
import AndroidLogo from "./icons/android.svg"
import ApacheLogo from "./icons/apache.svg"
import CassandraLogo from "./icons/cassandra.svg"
import JenkinsLogo from "./icons/jenkins.svg"
import TomcatLogo from "./icons/tomcat.svg"
import PythonLogo from "./icons/python.svg"
import ReactLogo from "./icons/react.svg"
import MysqlLogo from "./icons/mysql.svg"
import NodejsLogo from "./icons/nodejs.svg"
import SpringLogo from "./icons/spring.svg"
const cards = [
    {
        key: 646,
        loop: 1,
        content: <CardNews body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non turpis ligula. Donec lacinia iaculis libero, sit amet faucibus nulla vestibulum ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit." title="Apache" starNumber={5} image={CassandraLogo} images={[ApacheLogo]} />
    },
    {
        key: 9784968,
        loop: 1,
        content: <CardNews body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non turpis ligula. Donec lacinia iaculis libero, sit amet faucibus nulla vestibulum ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit." title="Jenkins" starNumber={2} image={CassandraLogo} images={[JenkinsLogo]} />
    },
    {
        key: 664465,
        loop: 2,
        content: <CardNews body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non turpis ligula. Donec lacinia iaculis libero, sit amet faucibus nulla vestibulum ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit." title="Cassandra" starNumber={5} image={CassandraLogo} images={[MysqlLogo, CassandraLogo]} />
    },
    {
        key: 985161,
        loop: 1,
        content: <CardNews body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non turpis ligula. Donec lacinia iaculis libero, sit amet faucibus nulla vestibulum ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit." title="Android" starNumber={3} image={CassandraLogo} images={[AndroidLogo]} />
    },
    {
        key: 6498,
        loop: 1,
        content: <CardNews body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non turpis ligula. Donec lacinia iaculis libero, sit amet faucibus nulla vestibulum ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit." title="Python" starNumber={3} image={CassandraLogo} images={[PythonLogo]}  />
    },
    {
        key: 61984,
        loop: 2,
        content: <CardNews body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non turpis ligula. Donec lacinia iaculis libero, sit amet faucibus nulla vestibulum ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit." title="React" starNumber={3} image={CassandraLogo} images={[ReactLogo, NodejsLogo]}  />
    },
    {
        key: 64849,
        loop: 2,
        content: <CardNews body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non turpis ligula. Donec lacinia iaculis libero, sit amet faucibus nulla vestibulum ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit." title="Tomcat" starNumber={3} image={CassandraLogo} images={[TomcatLogo, SpringLogo]}  />
    }
];

export default cards