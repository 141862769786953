import React from "react";
import "./About.css"
import { FaGithub } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { BrowserRouter as Router, Link } from 'react-router-dom';

const About = () => {
    return (
        <Router>
            <section className="about" id="about">
                <p className="welcome--about">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
                    inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                    fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem
                    sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora
                    incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad
                    minima veniam, quis nostrum exercitationem ullam corporis suscipit
                    laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum
                    iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae
                    consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                </p>
                <div className="break"></div>
                <div className='welcome--links'>
                    <Link target="_blank" to={{ pathname: "https://github.com/aggour211/" }}><FaGithub className="link--logo"  color="05386B" /></Link>
                    <Link target="_blank" to={{ pathname: "https://www.linkedin.com/in/issam-aggour-full-stack/" }}><FaLinkedin className="link--logo"  color="05386B" /></Link>
                    <Link target="_blank" to={{ pathname: "https://twitter.com/aggour_issam" }}><FaTwitter className="link--logo"  color="05386B" /></Link>
                </div>
            </section>
            <section className="about-2" id="portfolio">
                <p className="welcome--about">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
                    inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                    fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem
                    sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                    amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora
                    incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad
                    minima veniam, quis nostrum exercitationem ullam corporis suscipit
                    laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum
                    iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae
                    consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                </p>
            </section>
        </Router>
    )
}

export default About;