import strings from '../../assets/strings.js'
import {useState} from "react";
import "./Contact.css"
import Logo from "../../assets/images/contact.svg"

const Contact =  ({id, onAdd, language}) => {
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const onSubmit = (e) => {
        e.preventDefault()
        if(!email){
            alert('Enter Email')
            return
        }
        onAdd({name, company, email, phone, message})
        setName('')
        setCompany('')
        setEmail('')
        setPhone('')
        setMessage('')
    }
    return (
        <section id={id} className='contact-section'>
            <img src={Logo} alt="logo" className="contact-image" />
            <form id={id} className='contact-form' onSubmit={onSubmit}>
                <div className='form-control'>
                    <label>{strings[language.language].contact.name}</label>
                    <input type='text'
                    placeholder={strings[language.language].contact.name}
                    value={name}
                    onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div className='form-control'>
                    <label>{strings[language.language].contact.company}</label>
                    <input type='text'
                    placeholder={strings[language.language].contact.company}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}></input>
                </div>
                <div className='form-control'>
                    <label>{strings[language.language].contact.email}</label>
                    <input type='text'
                    placeholder={strings[language.language].contact.email}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}></input>
                </div>
                <div className='form-control'>
                    <label>{strings[language.language].contact.phone}</label>
                    <input type='text'
                    placeholder={strings[language.language].contact.phone}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}></input>
                </div>
                <div className='form-control'>
                    <label>{strings[language.language].contact.message}</label>
                    <input type='text'
                    placeholder={strings[language.language].contact.message}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}></input>
                </div>
                <input type='submit' value={strings[language.language].contact.send} className='btn btn-block'/>
            </form>
        </section>
    )
}

export default Contact
