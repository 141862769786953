import "./Navbar.css"
import Logo from "../../assets/images/logo.svg"
import French from "../../assets/images/french.png"
import English from "../../assets/images/english.png"
import strings from "../../assets/strings.js"
import { Link } from 'react-scroll'
import HamburgerMenu from "./HamburgerMenu";
import { useState } from 'react'

const Navbar = (language, setLanguage) => {
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false)
  return (
    <nav className="navbar navbar-sticky">
      <Link to="welcome">
        <div className="navbar--logo-holder">
          <img src={Logo} alt="logo" className="navbar--logo" />
          <h1>{strings[language.language].website}</h1>
        </div>
      </Link>
      <img src={French} alt="french" className="navbar--language" onClick={() => (language.setLanguage('fr'))}/>
      <img src={English} alt="english" className="navbar--language" onClick={() => (language.setLanguage('en'))} />
      <HamburgerMenu menu={showHamburgerMenu} onToggle={setShowHamburgerMenu} />
      <ul className={`navbar--link ${showHamburgerMenu ? 'open' : ''}`}>
        <li><Link onClick={() => { setShowHamburgerMenu(!showHamburgerMenu) }} className="navbar--link-item" activeClass="active" to="welcome" spy={true} smooth={true}>{strings[language.language].nav.home}</Link></li>
        <li><Link onClick={() => { setShowHamburgerMenu(!showHamburgerMenu) }} className="navbar--link-item" to="about" spy={true} smooth={true}>{strings[language.language].nav.about}</Link></li>
        <li><Link onClick={() => { setShowHamburgerMenu(!showHamburgerMenu) }} className="navbar--link-item" to="portfolio" spy={true} smooth={true}>{strings[language.language].nav.portfolio}</Link></li>
        <li><Link onClick={() => { setShowHamburgerMenu(!showHamburgerMenu) }} className="navbar--link-item" to="carroussel" spy={true} smooth={true}>{strings[language.language].nav.projects}</Link></li>
        <li><Link onClick={() => { setShowHamburgerMenu(!showHamburgerMenu) }} className="navbar--link-item" to="contact" spy={true} smooth={true}>{strings[language.language].nav.contact}</Link></li>
      </ul>
    </nav>
  )
}
export default Navbar