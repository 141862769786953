

import axios from 'axios'

const SendEmail = (form) => {
    const headers =  {
        'Accept': 'application/json'
    }
    axios.post('https://formspree.io/f/xdoypneg', form, {headers})
    .then(response => response.data.ok ? alert('Meassage sent successfully!'): alert('Error: message was not sent!'));
}

export default SendEmail
