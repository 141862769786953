import {useState} from "react"
// import useSticky from "./hooks/useSticky.js"
import Welcome from "./components/Header/Welcome"
import Navbar from "./components/Header/Navbar"
// import { useEffect } from "react"
function App() {
  const [language, setLanguage] = useState('fr')
  return (
    <>
      <Navbar language={language} setLanguage={setLanguage} />
      <Welcome language={language} />
    </>
  )
}

export default App