import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ChevronRightRounded from "@material-ui/icons/ChevronRightRounded";
import TextInfoContent from "@mui-treasury/components/content/textInfo";
import { useWideCardMediaStyles } from "@mui-treasury/styles/cardMedia/wide";
import { useN01TextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/n01";
import { useBouncyShadowStyles } from "@mui-treasury/styles/shadow/bouncy";
import Star from "../../assets/star";
import useInterval from "../../hooks/useInterval.js";
import { useState } from "react";


const CardView = ({ title, body, starNumber, images }) => {


  const [count, setCount] = useState(0)
  const [image, setImage] = useState(images[0])

  const changeBackgroundImage = () => {
    // console.log(count, image, images)
    let newCount = 0
    if(count+1 !== images.length){
      newCount = count+1
    }
    setImage(images[newCount])
    setCount(newCount)
  }
  useInterval(() => {
    // Votre logique métier ici
    changeBackgroundImage()
  }, 2000);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     changeBackgroundImage()
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [])

  const useStyles = makeStyles(() => ({
    root: {
      maxWidth: 304,
      width: 400,
      margin: "auto",
      boxShadow: "none",
      borderRadius: 30
    },
    content: {
      padding: 24
    },
    cta: {
      marginTop: 24,
      textTransform: "initial"
    }
  }));
  const styles = useStyles();
  const mediaStyles = useWideCardMediaStyles();
  const textCardContentStyles = useN01TextInfoContentStyles();
  const shadowStyles = useBouncyShadowStyles();

  return (
    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardMedia
        classes={mediaStyles}
        image={image}
        style={{
          height: 200
        }}
      />
      <CardContent className={styles.content}>
        {[...Array(starNumber)].map((e, i) => (
          <span key={i}>
            <Star height="20px" />
          </span>
        ))}
        <TextInfoContent
          classes={textCardContentStyles}
          //overline={"March 20, 2019"}
          heading={title}
          body={
            body
          }
        />
        <Button color={"primary"} fullWidth className={styles.cta}>
          Find Out More <ChevronRightRounded />
        </Button>
      </CardContent>
    </Card>
  )
}

export default CardView
