
import { FaGithub } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { BrowserRouter as Router, Link } from 'react-router-dom';
const Footer = () => {
    return (
        <Router>
            <footer>
                <p>Copyright &copy; AGGOUR {new Date().getFullYear()} Made with React. All Rights Reserved.</p>
                <div className='welcome--links'>
                    <Link target="_blank" to={{ pathname: "https://github.com/aggour211/" }}><FaGithub className="link--logo" color="9F94D7" /></Link>
                    <Link target="_blank" to={{ pathname: "https://www.linkedin.com/in/issam-aggour-full-stack/" }}><FaLinkedin className="link--logo"  color="9F94D7" /></Link>
                    <Link target="_blank" to={{ pathname: "https://twitter.com/aggour_issam" }}><FaTwitter className="link--logo"  color="9F94D7" /></Link>
                </div>
            </footer>
        </Router>
    )
}

export default Footer
