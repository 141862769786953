const strings = {
    fr: {
        website: 'OMRANG',
        nav: {
            home:'acceuil',
            about:'à propos',
            projects:'projets',
            portfolio:'portfolio',
            contact:'contact',

        },
        home: {
            title:'Bonjour, je suis Issam AGGOUR.',
            welcome:'Bienvenue sur mon site !',
            contact:'Contactez-moi'

        },
        welcome: {

        },
        about: {

        },
        contact: {
            name: 'Entrez votre nom complet',
            company: 'Entrez votre nom d\'entreprise',
            email: 'Entrez votre email',
            phone: 'Entrez votre numéro de téléphone',
            message: 'Message',
            send: 'Envoyez votre message',
        },
    },
    en: {
        website: 'OMRANG',
        nav: {
            home:'home',
            about:'about me',
            projects:'projects',
            portfolio:'portfolio',
            contact:'contact',
        },
        home: {
            title:'Hello, my name is Issam AGGOUR.',
            welcome:'Welcome to my website!',
            contact:'Contact me'

        },
        welcome: {

        },
        about: {

        },
        contact: {
            name: 'Tell us your name',
            company: 'Enter your company name',
            email: 'Enter your email',
            phone: 'Enter phone number',
            message: 'Message',
            send: 'Send your message',

        },


    }
}

export default strings